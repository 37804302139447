<!-- A设备管理-备件管理-备件入库记录 -->
<template>
    <div class="page_container height_100">
            <div class="box_head1">
                <span>备件入库记录</span>
                <div class="flex align_center mg_left_50 fn_size14">
                    <div class="">工单编号：</div>
                    <el-input v-model="queryCondition.workOrderNoInput" class="width_150" clearable></el-input>
                    <div class="mg_left_20">仓库：</div>
                    <!-- <el-input v-model="queryCondition.warehouseInput" class="width_150" clearable></el-input> -->
                    <el-select v-model="queryCondition.warehouseInput" clearable placeholder="请选择" class="width_150">
                        <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                            :value="item.value"> </el-option>
                    </el-select>
                    <div class="mg_left_20">申请时间：</div>
                    <el-date-picker v-model="queryCondition.produceTime" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-button type="primary" class="mg_left_10" @click="queryList" size="medium">
                        查询</el-button>
                </div>

            </div>

        <div class="bgc_FFFFFF bgc_FFFFFF_height pdl30 pdr30 ">
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" class="width_140 buttonBorder" style="background-color: #FEA313;"
                        @click="gotoAdd">
                        <!-- -->
                        <img src="../../../../assets/images/deviceManagement/a12.png" class="mg_right_2" alt="">
                        添加入库
                    </el-button>
                </div>
            </div>
            <!-- <div class="flex align_center pdt_20 fn_size14">
                <div class="">工单编号：</div>
                    <div>
                        <el-input v-model="workOrderNoInput" style="width: 180px;"></el-input>
                    </div>
                <div class="mg_left_20">记录时间：</div>
                <el-date-picker v-model="inspectionTimeValue" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
                <el-button type="primary" class="width_110 mg_left_10" style=" background: #009BFD;border: none;"
                    @click="query">查询
                </el-button>
            </div>
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" @click="gotoAddRepair"
                        style="background-color:#03CABE ;">添加</el-button>
                </div>
            </div> -->
                <el-table :data="sparePartInWarehouseArray" tooltip-effect="dark" style="width: 100%" class="mg_top_20"
                    height="80%" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width=""></el-table-column> -->
                    <el-table-column label="是否购用" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isPurchase?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="authSort" label="当前审批顺序" width=""></el-table-column>
                    <el-table-column label="是否审批" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAuth}}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="purchaseOrderID" label="购置工单编号" width=""></el-table-column> -->
                    <el-table-column prop="applyTime" label="申请时间" width="150" :formatter="formatDate">
                    </el-table-column>

                    <el-table-column prop="applyPerson" label="申请人" width=""></el-table-column>
                    <el-table-column prop="warehouse" label="仓库" width=""></el-table-column>
                    <el-table-column prop="department" label="部门" width=""></el-table-column>
                    <el-table-column prop="inwhUse" label="出库用途" width="" show-overflow-tooltip></el-table-column>

                    <el-table-column prop="orderStatus" label="工单状态" width=""></el-table-column>
                    <el-table-column prop="totalPrice" label="出库总价" width=""></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注" width="" show-overflow-tooltip></el-table-column> -->

                    <!-- <el-table-column prop="address" label="管理图片" width="" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;">管理</span>
                            </el-button>
                        </template>
                    </el-table-column> -->
                    <el-table-column label="详情">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails(scope.row.ID)">详细</span>
                                <!--   -->
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0"
                                @click="editTableData(scope.row.ID)">
                                <span style="border-bottom: 1px solid #009BFD;">修改</span>
                                <!-- -->
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" @click="delTableData(scope.row.ID)">
                                <span style="border-bottom: 1px solid #009BFD;">删除</span>
                                <!--  -->
                            </el-button>
                            <el-button type="text" class="" style="color_009BFD pdt_0 pdb_0" v-if="scope.row.doAuth">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="approvalOperation(scope.row.ID)">审批</span>
                            </el-button>
                            <!-- <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="!scope.row.doAuth">
                            已审批</el-button> -->
                        </template>
                    </el-table-column>

                </el-table>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    class="mg_top_10" :current-page.sync="currentPage" @current-change="handleCurrentChange">
                </el-pagination>
            </div>

            <!-- 审批弹框 -->
            <el-dialog :visible.sync="dialogVisible" width="30%" top="30vh">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <div class="height_110 flex justify_center align_center">
                    <el-radio-group v-model="approvalListValue" class="">
                        <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                            {{item.description}}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confrimApproval">确 定</el-button>
                </div>
            </el-dialog>
            <!-- 审批弹框结束 -->

    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        sparePartWarehouseQuery,
        sparePartInWarehouseDelete,
        multiAuthorizeResultQuery1,
        sparePartInWarehouseAuthorize,
        sparePartInWarehouseMultiAuthQueryLimit,
    } from "@/api/api_deviceManagement";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                workOrderNoInput: '',
                warehouseInput: '',
                produceTime: null,
                multipleSelection: [],
                sparePartInWarehouseArray: [],
                tabelData: [],

                //备件入库单筛选-分页数据 所需数据
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                userID: '',


                //备件入库单筛选-指定条件筛选条件 仓库下拉框 使用的数组
                warehouseSelectList: [],

                //备件入库单筛选-指定条件筛选-分页数据 所需变量
                queryCondition: {
                    warehouseInput: '',
                    workOrderNoInput: '',
                    produceTime: null,
                },

                //审批所学的变量
                approvalID: '', //要审批的ID
                approvalList: [], //获取的审批结果列表内容
                approvalListValue: '', //初始时使用的值
                dialogVisible: false, //审批弹框显示控制标识符

            }
        },
        // 页面初始化数据，所有一进页面就需要展示的内容
        mounted() {
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            this.sparePartInWarehouseMultiAuthQueryLimit();

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
        },
        methods: {

            //仓库下拉框
            async sparePartWarehouseQuery() {

                //封装参数
                var param = {
                    userID: this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },

            //分页筛选
            async sparePartInWarehouseMultiAuthQueryLimit() {

                /*                 if ((this.queryCondition.workOrderNoInput != '')||(this.queryCondition.warehouseInput != '')||(this.queryCondition.produceTime != null)){
                                    this.currentPage = 1;
                                } */
                //封装参数
                var tableParam = {
                    // userID: this.userID
                    userID: this.userID,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                };
                if (this.queryCondition.workOrderNoInput != '') {
                    tableParam.orderID = this.queryCondition.workOrderNoInput;
                }
                if (this.queryCondition.warehouseInput != '') {
                    tableParam.warehouse = this.queryCondition.warehouseInput;
                }
                if (this.queryCondition.produceTime != null) {
                    tableParam.startApplyTime = this.tools.formatDate(this.queryCondition.produceTime[0],
                        'yyyy-MM-ddThh:mm:ss');
                    tableParam.endApplyTime = this.tools.formatDate(this.queryCondition.produceTime[1],
                        'yyyy-MM-ddThh:mm:ss');
                }

                //调用接口
                const tableRes = await sparePartInWarehouseMultiAuthQueryLimit(tableParam);

                //接收数据
                this.sparePartInWarehouseArray = tableRes.array;
                this.pageQuantity = tableRes.pageQuantity;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleChange(id) {
                this.tabIndex = id;
            },
            queryList() {
                this.currentPage = 1;
                this.sparePartInWarehouseMultiAuthQueryLimit();
            },
            handleCurrentChange(val) {
                // this.selectOnDuty(); //查询table数据的方法
                this.sparePartInWarehouseMultiAuthQueryLimit();
            },
            // 添加入库记录
            gotoAdd() {
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartWarehousingRecord/addSparePartWarehousingRecord',
                })
            },
            // 编辑
            editTableData(id) {
                localStorage.setItem('ID', id);
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartWarehousingRecord/updateSparePartWarehousingRecord',
                    // query: {
                    //     data: data
                    // }
                })
            },
            // 删除
            delTableData(id) {
                // sparePartInWarehouseDelete
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.currentPage = this.delData.delData(this.sparePartInWarehouseArray.length, this
                        .pageQuantity, this.currentPage)
                    this.sparePartInWarehouseDelete(id);
                }).catch(() => {});
            },
            async sparePartInWarehouseDelete(id) {
                var param = {
                    ID: id
                }
                const res = await sparePartInWarehouseDelete(param);
                if (res && res.data.result) {
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.sparePartInWarehouseMultiAuthQueryLimit();
                } else {
                    this.$message.error(res.message);
                }
            },
            //详情
            gotoFaultDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartWarehousingRecord/sparePartWarehousingRecordDetails',
                })
            },
            //备件入库单审批
            //点击‘审批’按钮时触发的方法
            async approvalOperation(id) {

                this.approvalID = id;

                //调用接口。查询审批结果列表的内容
                const selectRes = await multiAuthorizeResultQuery1();

                //装载审批结果列表数据
                this.approvalList = selectRes.array;
                this.approvalListValue = selectRes.array[0].value;

                //打开审批弹框
                this.dialogVisible = true;
            },
            //点击审批弹框中的‘确定’按钮时触发的方法
            async confrimApproval() {

                //封装参数
                var param = {
                    userId: this.userID,
                    ID: this.approvalID,
                    authResult: this.approvalListValue,
                };

                //调用接口
                const selectRes = await sparePartInWarehouseAuthorize(param);

                //处理返回值
                if (selectRes.data.result) { //如果有返回值
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisible = false;

                    //调用分页查询接口，刷新审批操作之后的数据
                    this.sparePartInWarehouseMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                }
            },

            formatDate(row, column) {
                // 获取单元格数据,时间格式化
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .spareButton {
        width: 110px;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>